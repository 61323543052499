import React from "react"

import Page from "../../components/Page"
import BannerParallax from "../../components/BannerParallax"

import imgBackground from "../../images/banner/banner-1-music-school.jpg"
import OurClass from "./MusicSchoolClass"

export default () => {
  const pageTitle = "Music School"
  return (
    <Page title={pageTitle}>
      <BannerParallax title={pageTitle} background={imgBackground} />
      <OurClass />
    </Page>
  )
}
